html,
body,
#root {
    width: 100%;
    height: 100%;
}

.app {
    width: 100%;
    height: 100%;
}

/*.year {*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    justify-content: space-between;*/
/*}*/

.month {
    margin: 5px 5px 15px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.month-name {
    color: white;
    font-weight: bold;
}

.day {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
}

.date {
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50px;
    border: none;
    outline: none;
}

.date.in-month:hover {
    cursor: pointer;
    background: #373737;
}

.today {
    background: #434343;
    color: #ffffff;
    font-weight: bold;
}

.date.prev-month,
.date.next-month {
    color: #a9a9a9;
}
