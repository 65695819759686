.simple-tools-page {
  .main-row {
    min-height: 60vh;
  }
  .left-input-col {
    .card {
      height: 100%;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .form-control {
        color: black;
      }

      .input-subtitle{
        opacity: 0.7;
        font-size: .8rem;
        margin-left: 0.5rem;
      }
    }
  }

  .audience {
    min-height: 800px;
  }

  .right-input-col {
    .card {
      border-left: 0;
      height: 100%;
      background-color: #1C1C1C;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 600px;
        @media (max-width: 990px) {
          justify-content: center;
          min-height: 400px;
        }
      }

      .calculated-results{
        justify-content: flex-start;  
        .result {
          margin-top: 1.5rem;
        }
      }

      .result {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        //background-color: #00000052;
        z-index: 3;

&:first-child{
 
}

span {
  font-size: 1.5rem;
}

        .result-grid-row {
          width: 100%;
          margin-bottom: 4rem;
        }
      }

       

      .result-grid-value {
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        color: white;
      }

      .result-grid-label {
        font-size: 0.8rem;
        text-align: center;
        color: white;
      }
      .footer-scene-image-gya{
        width: 100%;
        position: absolute;
        bottom: 0;
      }
    }
  }

  .tool-form-row {
    padding: 1rem 0;

    .tool-form-label {
      display: flex;
      align-items: center;
      //justify-content: center;
    }
  }
}
